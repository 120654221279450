<ae2-header-widget [config]="headerConfig">
    <ng-template #headerTemplate let-header let-config="config" let-settings="settings">
        <ng-container *ngIf="header.isPreHeaderOpened">
            <ng-container *ngIf="!header.isMobile; else mobilePreHeader">

                <div class="ae2-header-widget-login-or-signup" fxLayout="row" fxLayoutAlign="center start">

                    <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
                        (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()"
                        (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
                    <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
                        (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
                        (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
                    <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
                        (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
                        (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #mobilePreHeader>
            <div class="ae2-header-widget-pre-header-mobile">
                <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
                    (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()" (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
                <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
                    (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
                    (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
                <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
                    (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
                    (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
            </div>
        </ng-template>

        <div class="ae2-header-widget-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <ae2-header-social-widget [template]="header.headerSocialTemplate" [settings]="settings.site" [config]="config"></ae2-header-social-widget>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <ae2-header-login-widget [template]="header.headerLoginTemplate" [isLoginInHeader]="config.isLoginInHeader"
                    (onOpenLogin)="header.openLogin()" (onOpenSignup)="header.openSignup()">
                    <ng-template #customTemplate let-login let-isLoginInHeader="isLoginInHeader">
                        <div fxFlex="row" fxLayoutAlign="end center" fxHide.xs fxLayoutGap="10px" class="ae2-header-login">

                            <ng-container *ngIf="login.isLoggedIn; else notLoggedIn">
                                <ng-container *ngIf="login.authStore$ | async as authStore">

                                    <ng-container *ngIf="authStore.user.isImpersonate; else notImpersonate">
                                        <span class="cursor" routerLink="/minha-conta/meus-dados">{{
                                            authStore.user.impersonatorName }} ({{ authStore.user.firstName }})</span>
                                        <span class="ae2-header-divider1">|</span>
                                    </ng-container>
                                    <ng-template #notImpersonate>
                                        <span class="cursor" routerLink="/minha-conta/meus-dados">
                                            Olá {{ authStore.user.firstName}}
                                        </span>
                                        <span>|</span>
                                    </ng-template>

                                    <span fxHide.xs fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                                        <ng-container *ngIf="authStore.user.isImpersonate; else exitNoImpersonate">
                                            <a href="/impersonate/stop/">
                                                sair
                                            </a>
                                        </ng-container>
                                        <ng-template #exitNoImpersonate>
                                            <a (click)="login.logOut()">
                                                sair
                                            </a>
                                        </ng-template>
                                    </span>
                                </ng-container>
                            </ng-container>

                            <ng-template #notLoggedIn>
                                <ng-container *ngIf="login.isLoginInHeader; else toLoginPage">
                                    <a (click)="login.openLogin()">
                                        Entrar
                                    </a>
                                </ng-container>
                                <ng-template #toLoginPage>
                                    <a href="#" routerLink="/login">
                                        Entrar
                                    </a>
                                </ng-template>
                            </ng-template>

                        </div>
                    </ng-template>
                </ae2-header-login-widget>
            </div>
        </div>
        <div class="ae2-header-widget-bottom" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutAlign.xs="center stretch">
            <div class="ae2-header-mobile-menu" fxHide.gt-sm (click)="sidenavComponent.openSidenav()">
                <mat-icon>menu</mat-icon>
            </div>
            <ae2-header-logo-widget fxFlex="30%" fxFlex.xs="auto" [template]="header.headerLogoTemplate"
                [isTransparent]="config.hasTransparentRoutes"></ae2-header-logo-widget>
            <ae2-header-menu-widget fxFlex="70%" fxHide.xs="true" [template]="header.headerMenuTemplate"
                [isSubmenuOpened]="header.submenuVisibility" [items]="config.menuItems" (onSubitemsShow)="header.populateSubitems($event)">
                <ng-template #headerSocialWidget let-menu>
                    <div class="ae2-header-menu" fxLayout="row" fxLayoutAlign="end stretch">
                        <div *ngFor="let item of menu.items.items" class="ae2-header-menu-item" fxLayout="row"
                            fxLayoutAlign="center stretch">
                            <div class="ae2-header-item-page" *ngIf="item.type === 'PAGE'" fxLayout="row" fxLayoutAlign="center stretch">
                                <a [routerLink]="item.url" fxLayout="row" fxLayoutAlign="center center">
                                    <span>{{ item.label }}</span>
                                </a>
                            </div>
                            <div class="ae2-header-item-session" *ngIf="item.type === 'SESSION'" fxLayout="row"
                                fxLayoutAlign="center stretch">
                                <a (click)="menu.navigator(item.url)" fxLayout="row" fxLayoutAlign="center center">
                                    <span>{{ item.label }}</span>
                                </a>
                            </div>
                            <div class="ae2-header-item-subitems" *ngIf="item.type === 'SUBITEMS'" fxLayout="row"
                                fxLayoutAlign="center stretch">
                                <a (click)="menu.submenu(item.subitems)" fxLayout="row" fxLayoutAlign="center center">
                                    <span>{{ item.label }}</span>
                                    <mat-icon [class.active]="menu.isSubmenuOpened">keyboard_arrow_down</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </ae2-header-menu-widget>
        </div>
        <div class="ae2-header-widget-subitems" fxLayout="row" fxLayoutAlign="end stretch">
            <ae2-header-submenu-widget fxHide.xs="true" [template]="header.headerSubmenuTemplate" [items]="header.getSubmenuItems()"
                [class.active]="header.submenuVisibility" [style.top]="preHeaderHeight + header.headerHeight + 'px'"
                [backgroundColor]="header.getBackgroundColor()" (onClose)="header.closeSubmenu()"></ae2-header-submenu-widget>
        </div>
        <ae2-header-sidenav-widget #sidenavComponent [template]="header.headerSidenavTemplate" [items]="config.menuItems"
            (onOpenLogin)="header.openLogin()" (onOpenSignup)="header.openSignup()">
            <ng-template #customTemplate let-sidenav>
                <div class="ae2-header-sidenav-closer" [class.active]="sidenav.isOpened" (click)="sidenav.closeSidenav()">&nbsp;</div>
                <div class="ae2-header-sidenav" [class.active]="sidenav.isOpened">

                    <ng-container *ngIf="sidenav.isLoggedIn; else notLoggedIn">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-dados"
                                fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <mat-icon>account_circle</mat-icon>
                                <span *ngIf="(sidenav.authStore$ | async) as authStore">
                                    Olá {{authStore.user.firstName}}
                                </span>
                            </div>

                            <ng-template [ngTemplateOutlet]="menuItems"></ng-template>

                            <ng-container *ngFor="let item of sidenav.myAccountMenu">
                                <div (click)="sidenav.closeSidenav()" [routerLink]="['/', 'minha-conta', item.link]"
                                    class="ae2-header-sidenav-item" routerLinkActive="active-link" fxLayout="row"
                                    fxLayoutGap="10px" fxLayoutAlign="start center">
                                    {{item.name}}
                                </div>
                            </ng-container>

                            <div class="ae2-header-sidenav-item" (click)="sidenav.logOut(); sidenav.closeSidenav()"
                                fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <mat-icon>exit_to_app</mat-icon>
                                sair
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #notLoggedIn>
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openLogin()"
                                fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <span>
                                    Entrar
                                </span>
                            </div>
                            <ng-template [ngTemplateOutlet]="menuItems"></ng-template>
                        </div>
                    </ng-template>

                    <ng-template #menuItems>

                        <ng-container *ngFor="let item of sidenav.items.items">
                            <div *ngIf="['PAGE','SESSION'].indexOf(item.type) >= 0" class="ae2-header-sidenav-item"
                                fxLayout="row" fxLayoutAlign="start center">
                                <div class="ae2-header-sidenav-item-page" *ngIf="item.type === 'PAGE'">
                                    <a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                                        <span>{{ item.label }}</span>
                                    </a>
                                </div>
                                <div class="ae2-header-sidenav-item-session" *ngIf="item.type === 'SESSION'">
                                    <a (click)="sidenav.navigator(item.url); sidenav.closeSidenav()" class="sidenav-link">
                                        <span>{{ item.label }}</span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="sidenav.subitems && sidenav.subitems.length > 0">
                            <div *ngFor="let subitem of sidenav.subitems" class="ae2-header-sidenav-item" fxLayout="row"
                                fxLayoutAlign="start center">
                                <div class="ae2-header-sidenav-item-page" *ngIf="subitem.type === 'PAGE'">
                                    <a [routerLink]="subitem.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                                        <span>{{ subitem.label }}</span>
                                    </a>
                                </div>
                                <div class="ae2-header-sidenav-item-session" *ngIf="subitem.type === 'SESSION'">
                                    <a (click)="sidenav.navigator(subitem.url); sidenav.closeSidenav()" class="sidenav-link">
                                        <span>{{ subitem.label }}</span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>


                </div>
            </ng-template>
        </ae2-header-sidenav-widget>
    </ng-template>
</ae2-header-widget>
<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>