import { Component, OnInit } from '@angular/core';
import {
  Ae2HeaderWidgetConfig,
  Ae2HeaderMenu,
  Ae2HeaderLinkToPage
} from '@angularecommerce/core/components/header-widget';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();

  ngOnInit(): void {
    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderLinkToPage('Procedimento do Associado', '/procedimento'),
      new Ae2HeaderLinkToPage('Agenda', '/agenda'),
    ];
    this.headerConfig.transparentOnRoutes = [];
    this.headerConfig.backgroundColor = '#0067AB';
  }

}
