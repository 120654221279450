import { NgModule } from '@angular/core';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { HeaderComponent } from './header.component';
import { Ae2RouterClassesModule } from '@angularecommerce/core/directives/router-classes';
import { Ae2SignInTopModule } from '@angularecommerce/core/components/sign-in-top';
import { Ae2ForgotPasswordTopModule } from '@angularecommerce/core/components/forgot-password-top/forgot-password-top.module';
import { Ae2ForgotPasswordDoneTopModule } from '@angularecommerce/core/components/forgot-password-done-top/forgot-password-done-top.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2RouterClassesModule,
    Ae2SignInTopModule,
    Ae2HeaderWidgetModule,
    MatIconModule,
    RouterModule,
    Ae2ForgotPasswordTopModule,
    Ae2ForgotPasswordDoneTopModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule { }
