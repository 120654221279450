import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'procedimento',
    loadChildren: 'app/features/procedimento/procedimento.module#ProcedimentoModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
