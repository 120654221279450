/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angularecommerce/core/components/carousel-container/carousel-container.component.ngfactory";
import * as i3 from "@angularecommerce/core/components/carousel-container/carousel-container.component";
import * as i4 from "@angularecommerce/core/services/carousel-banners/carousel-banners.service";
import * as i5 from "@angularecommerce/core/components/carousel/carousel-overlay-content.directive";
import * as i6 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ae2Carousel__overlay-content-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h2", [["class", "ae2Carousel__overlay-content-default__title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "h3", [["class", "ae2Carousel__overlay-content-default__subtitle"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.description; _ck(_v, 2, 0, currVal_1); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ae2-carousel-container", [["carouselCode", "home"]], null, null, null, i2.View_Ae2CarouselContainerComponent_0, i2.RenderType_Ae2CarouselContainerComponent)), i1.ɵdid(1, 49152, null, 1, i3.Ae2CarouselContainerComponent, [i4.Ae2CarouselBannersService, i1.ChangeDetectorRef], { config: [0, "config"], carouselCode: [1, "carouselCode"] }, null), i1.ɵqud(335544320, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_HomeComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.Ae2CarouselOverlayContentDirective, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselConfig; var currVal_1 = "home"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i6.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i6.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
