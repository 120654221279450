<footer>
    <div class="ae2-footer_bottom" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
        <div>
            Copyright © 1899-{{currentYear}}. Todos os direitos reservados.
        </div>
        <div>
            <a href="https://angulare.app/?utm_source=cws&utm_campaign=ecp" target="_blank" class="ae2-footer_bottom_powered">
                Powered by Angular e-Commerce
            </a>
        </div>
    </div>
</footer>