<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent 
    let-title="title" 
    let-description="description" 
    let-ctaLabel="ctaLabel" 
    let-ctaUrl="ctaUrl">
        <div class="ae2Carousel__overlay-content-default">
            <h2 class="ae2Carousel__overlay-content-default__title" [innerHTML]="title"></h2>
            <h3 class="ae2Carousel__overlay-content-default__subtitle" [innerHTML]="description"></h3>
        </div>
    </ng-template>
</ae2-carousel-container>